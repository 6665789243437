import React from "react";
import { useStatePersist } from "use-state-persist";

import { Link } from "gatsby";

import ScreenReader from "./ScreenReader";

const SmartFeedJobCard = ({ 
  job, 
  apiKey,
  viewBySelection,
  siteConfig, 
  trackEvent,
  }) => {
  const [savedJobs, setSavedJobs] = useStatePersist("@savedJobs_" + apiKey, []);

  function unsaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "unsaveJob",
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  function saveJob(id) {
    setSavedJobs((savedJobsOld) => [...savedJobsOld, id]);
    if (trackEvent) {
      trackEvent({
        eventCategory: "SmartFeedListComponent",
        eventAction: "saveJob",
        eventLabel: encodeURIComponent(id),
      });
    }
  }

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = "long";
    dateFormatOptions.year = "numeric";
    dateFormatOptions.month = "short";
    dateFormatOptions.day = "numeric";
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  return (
    <div className="job-card card" id={new Buffer(job.Id).toString("base64")}>
      <div className="job-card-details">
        <div className="job-card-title" title={job.JobTitle}>
          <Link
            to={
              siteConfig.companyCareersSite
                ? "/vacancies/vacancy/" +
                  new Buffer(job.Id).toString("base64") +
                  "/" +
                  slugify(job.JobTitle) +
                  window.location.search
                : "/vacancies/" +
                  apiKey +
                  "/vacancy/" +
                  new Buffer(job.Id).toString("base64") +
                  "/" +
                  slugify(job.JobTitle) +
                  window.location.search
            }
          >
            {job.JobTitle}
          </Link>
        </div>
        <div className="card-actions">
          <div className="job-card-savejob">
            <div
              title="Save this job for later"
              className="job-card-savejob-trigger"
              role="button"
              onClick={() => {
                if (
                  savedJobs.filter((e) => e == job.Id.toString()).length > 0
                ) {
                  unsaveJob(job.Id);
                } else {
                  saveJob(job.Id);
                }
              }}
            >
              {savedJobs.filter((e) => e == job.Id.toString()).length > 0 ? (
                <span className="fas fa-heart"></span>
              ) : (
                <span className="far fa-heart"></span>
              )}
            </div>
          </div>
          <ScreenReader
            text={`${job.JobTitle}, 
              this role is for ${job.Region}, 
              part of ${job.JobCategory}, 
              based in ${job.Location}. 
              The contract type for this position is ${job.JobType} / ${
              job.JobTypeTime
            }, 
              this vacancy expires on ${FormatDateTime(job.ExpiryDate)}. 
              Please click the apply button below to find out more.`}
          />
        </div>

        <div className="job-card-info-logo">
          <img
            src={
              job.Region
                ? `/img/schools/${job.Region.replace(/[, ]+/g, "")}.jpg`
                : "/img/schools/TheDeanTrustCentral.jpg"
            }
            alt={job.Region ? job.Region : job.Company}
            onError={(e) => {
              e.target.src = "/img/schools/TheDeanTrustCentral.jpg";
            }}
          />
        </div>
        <div className="job-card-info-items my-2">
          <div className="job-card-type">
            <strong>Category: </strong>
            {job.JobCategory}
          </div>
          <div className="job-card-type">
            <strong>Location: </strong>
            {job.Location}
          </div>
          <div className="job-card-type">
            <strong>Job Type: </strong>
            {`${job.JobType} / ${job.JobTypeTime}`}
            {job.JobDuration ? ` (${job.JobDuration})` : ""}
          </div>
          <div className="job-card-type">
            <strong>Closing Date: </strong>
            {FormatDateTime(job.ExpiryDate)}
          </div>
        </div>

        <div className="job-card-buttons-wrap">
          <Link
            className="job-card-button btn--primary"
            to={
              siteConfig.companyCareersSite
                ? "/vacancies/vacancy/" +
                  new Buffer(job.Id).toString("base64") +
                  "/" +
                  slugify(job.JobTitle) +
                  window.location.search
                : "/vacancies/" +
                  apiKey +
                  "/vacancy/" +
                  new Buffer(job.Id).toString("base64") +
                  "/" +
                  slugify(job.JobTitle) +
                  window.location.search
            }
          >
            More Information
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedJobCard;
