import React, { useEffect, useState } from "react";
import Speech from "speak-tts";

const ScreenReader = ({ text }) => {
  const [isSupported, setIsSupported] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const speech = new Speech();
  useEffect(() => {
    if (speech.hasBrowserSupport()) {
      setIsSupported(true);
    }
  }, []);

  speech.init({
    volume: 1,
    lang: "en-GB",
    rate: 0.9,
    pitch: 1,
    voice: "Google UK English Female",
  });

  const onSpeak = () => {
    if (isSpeaking) {
      speech.cancel();
      setIsSpeaking(false);
      return;
    }
    if (!speech.pending()) {
      speech.speak({
        text: text,
        queue: false,
      });
      setIsSpeaking(true);
    }
  };

  return isSupported ? (
    <button className="btn btn-link screen-reader" onClick={onSpeak}>
      {isSpeaking ? (
        <span className="fas fa-stop"></span>
      ) : (
        <span className="fas fa-volume-up"></span>
      )}
    </button>
  ) : null;
};

export default ScreenReader;
